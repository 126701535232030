import React, { Fragment, useState, useEffect } from 'react';
import { Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import Response from '../components/Response';
import SubQuestion from '../components/SubQuestion';
import SubQuestions from '../components/SubQuestions';

const StyledTitle = styled(Text)`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.14;
  color: #161f26;
`;

const ResponseOptions = ({
  questionId,
  responseOptions,
  subQuestions,
  responsesSection,
  setResponsesSection,
  number,
  moveDown,
  initSection
}) => {
  const [activeQ, setActiveQ] = useState(null);
  const [activeSQ, setActiveSQ] = useState(null);
  const [init, setInit] = useState(true);

  const questionExistsOnArray = (questionId, arr) => {
    return arr.some(item => item.questionId === questionId);
  };

  const handleClickResponse = (
    questionId,
    subQuestionId,
    responseId,
    hasSQ
  ) => {
    const n = number + 1;
    let arrResponses = [...responsesSection];

    if (!hasSQ) moveDown('q' + n);

    if (subQuestionId == null) {
      setActiveQ(responseId);
      if (questionExistsOnArray(questionId, arrResponses)) {
        let arrWithoutQuestion = arrResponses.filter(
          item => item.questionId !== questionId
        );
        arrResponses = [...arrWithoutQuestion];
      }
      arrResponses.push({ questionId: questionId, responseId: responseId });
      setResponsesSection(arrResponses);
    } else {
      setActiveSQ(responseId);

      let arrQuestion = arrResponses.filter(
        item => item.questionId == questionId
      );
      let element = {
        questionId: questionId,
        responseId: arrQuestion[0]['responseId'],
        subQuestions: [{ questionId: subQuestionId, responseId: responseId }]
      };
      let arrWithoutQuestion = arrResponses.filter(
        item => item.questionId !== questionId
      );
      arrWithoutQuestion.push(element);
      setResponsesSection(arrWithoutQuestion);
    }
  };

  const handleClickResponseSubQuestions = (
    questionId,
    subQuestionId,
    responseId,
    lastSQ
  ) => {
    if (lastSQ) {
      const n = number + 1;
      moveDown('q' + n);
    }

    let arrSubQuestions = [];
    let arrResponses = [...responsesSection];
    if (questionExistsOnArray(questionId, arrResponses)) {
      let arrQuestion = arrResponses.filter(
        item => item.questionId == questionId
      );
      arrSubQuestions = arrQuestion[0]['subQuestions'];
      let arrWithoutQuestion = arrResponses.filter(
        item => item.questionId !== questionId
      );

      if (questionExistsOnArray(subQuestionId, arrSubQuestions)) {
        let arrWithoutSubQuestion = arrSubQuestions.filter(
          item => item.questionId !== subQuestionId
        );
        arrSubQuestions = [...arrWithoutSubQuestion];
      }
      arrSubQuestions.push({
        questionId: subQuestionId,
        responseId: responseId
      });
      arrWithoutQuestion.push({
        questionId: questionId,
        subQuestions: arrSubQuestions
      });
      setResponsesSection(arrWithoutQuestion);
    } else {
      arrSubQuestions.push({
        questionId: subQuestionId,
        responseId: responseId
      });
      arrResponses.push({
        questionId: questionId,
        subQuestions: arrSubQuestions
      });
      setResponsesSection(arrResponses);
    }
  };

  useEffect(() => {
    if (initSection && init) {
      if (responseOptions) {
        responseOptions.map(option =>
          option.selected ? setActiveQ(option.contentful_id) : ''
        );
      }

      if (subQuestions) {
        subQuestions[0].question.responseOptions.map(option =>
          option.selected ? setActiveSQ(option.contentful_id) : ''
        );
      }
      setInit(false);
    }
  });

  return (
    <Fragment>
      <Flex
        alignItems="center"
        justifyContent={`${
          responseOptions != null && responseOptions.length < 3
            ? 'space-around'
            : ''
        }`}
        flexDirection={`${
          responseOptions != null && responseOptions.length > 2 ? 'column' : ''
        }`}
      >
        {responseOptions &&
          responseOptions.map(option => (
            <Response
              key={option.contentful_id}
              questionId={questionId}
              subQuestionId={null}
              responseId={option.contentful_id}
              responseText={option.responseText}
              icon={option.icon}
              width={`${
                responseOptions != null && responseOptions.length > 2
                  ? '250px'
                  : '140px'
              }`}
              handleClickResponse={handleClickResponse}
              isActive={activeQ === option.contentful_id}
              hasSQ={
                subQuestions &&
                subQuestions[0].response.contentful_id == option.contentful_id
              }
            />
          ))}
      </Flex>

      <Flex pl={5} flexDirection="column">
        {subQuestions && subQuestions.length == 1 && (
          <SubQuestion
            questionId={questionId}
            subQuestions={subQuestions}
            number={number}
            handleClickResponse={handleClickResponse}
            activeQ={activeQ}
            activeSQ={activeSQ}
          />
        )}
        {subQuestions && subQuestions.length > 1 && (
          <SubQuestions
            questionId={questionId}
            subQuestions={subQuestions}
            handleClickResponseSubQuestions={handleClickResponseSubQuestions}
          />
        )}
      </Flex>
    </Fragment>
  );
};

export default ResponseOptions;
