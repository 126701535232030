import React from 'react';
import Rodal from 'rodal';
import { Text, Flex, Button } from 'rebass/styled-components';
import styled from 'styled-components';
import 'rodal/lib/rodal.css';

const ModalText = styled(Text)`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #31373b;
`;

const ModalConfirm = ({
  description,
  question,
  confirmMethod,
  showModal,
  setShowModal
}) => {
  return (
    <Rodal visible={showModal} onClose={() => setShowModal(false)} width={300}>
      <ModalText mt={25}>{description}</ModalText>
      <ModalText my={5} textAlign="center">
        {question}
      </ModalText>

      <Flex justifyContent="space-around">
        <Button
          variant="bDarkRed"
          width="82px"
          onClick={() => setShowModal(false)}
        >
          No
        </Button>
        <Button
          variant="bDarkRed"
          width="82px"
          onClick={() => {
            confirmMethod();
            setShowModal(false);
          }}
        >
          Yes
        </Button>
      </Flex>
    </Rodal>
  );
};

export default ModalConfirm;
