import React from 'react';
import { Box, Image, Text, Flex } from 'rebass/styled-components';
import styled from 'styled-components';

const Rectangle = styled(Box)`
  border-radius: 25px;
  border: solid 1px #f0f5f6;
  font-size: 16px;
  line-height: 1.31;
  background-color: #ffffff;
  cursor: pointer;
  text-align: center;
`;

const Response = ({
  questionId,
  subQuestionId,
  responseId,
  responseText,
  icon,
  width,
  handleClickResponse,
  isActive,
  hasSQ
}) => {
  return (
    <Rectangle
      key={responseId}
      width={width}
      my={2}
      px={5}
      py={2}
      onClick={() =>
        handleClickResponse(questionId, subQuestionId, responseId, hasSQ)
      }
      style={{
        color: isActive ? '#000' : ' #8b97a2',
        fontWeight: isActive ? 'bold' : 'normal',
        border: isActive ? 'solid 4px #f0f5f6' : 'solid 1px #f0f5f6'
      }}
    >
      <Flex
        flexWrap="wrap"
        alignItems="center"
        justifyContent={icon ? '' : 'center'}
        ml={icon ? '5px' : ''}
      >
        {icon && (
          <Image
            src={icon.file.url}
            alt={icon.file.fileName}
            width="24px"
            mr={3}
            style={{
              opacity: isActive ? '1' : '.3'
            }}
          ></Image>
        )}
        <Text py={icon ? '0px' : '2px'}>{responseText}</Text>
      </Flex>
    </Rectangle>
  );
};

export default Response;
