import React, { useState, useEffect, Fragment } from 'react';
import { Flex, Box, Button } from 'rebass/styled-components';
import StyledRectangle from '../components/StyledRectangle';
import Question from '../components/Question';
import ResponseOptions from '../components/ResponseOptions';
import scrollTo from 'gatsby-plugin-smoothscroll';
import CardSubtitle from '../components/CardSubtitle';
import Line from '../components/Line';
import ModalConfirm from '../components/ModalConfirm';
import Modal from '../components/Modal';

const QuizSection = ({
  sectionNumber,
  data,
  goToStep,
  steps,
  goBack,
  initSection,
  setInitSection,
  responsesSection,
  setResponsesSection,
  arreResponsesPreviousSection
}) => {
  const [totalResponses, setTotalResponses] = useState(1);
  const [next, setNext] = useState(false);
  const [weightingResponses, setWeightingResponses] = useState([]);
  const [showModalBack, setShowModalBack] = useState(false);
  const [showModalNext, setShowModalNext] = useState(false);

  const existsElementOnArray = (questionId, arr) => {
    return arr.some(item => item.questionId === questionId);
  };

  useEffect(() => {
    let t = 0;
    let w = [];
    let previousResponse = '';
    let previousResponse2 = '';

    if (responsesSection && responsesSection.length && !initSection) {
      data.questions.map(question => {
        if (question.responseOptions) {
          if (existsElementOnArray(question.contentful_id, responsesSection))
            previousResponse = responsesSection.filter(
              item => item.questionId == question.contentful_id
            );

          question.responseOptions.map(response => {
            w[question.contentful_id + response.contentful_id] = 1;
            response.selected =
              previousResponse[0].responseId == response.contentful_id
                ? true
                : false;
          });
          t += 1;

          if (question.subQuestions) {
            question.responseOptions.map(response => {
              w[question.contentful_id + response.contentful_id] += 1;
            });

            question.subQuestions.map(subQuestion => {
              if (previousResponse[0].subQuestions) {
                if (
                  existsElementOnArray(
                    subQuestion.question.contentful_id,
                    previousResponse[0].subQuestions
                  )
                )
                  previousResponse2 = previousResponse[0].subQuestions.filter(
                    item =>
                      item.questionId == subQuestion.question.contentful_id
                  );
              }

              subQuestion.question.responseOptions.map(response => {
                w[
                  subQuestion.question.contentful_id + response.contentful_id
                ] = 1;
                if (previousResponse2 && previousResponse2.length) {
                  response.selected =
                    previousResponse2[0].responseId == response.contentful_id
                      ? true
                      : false;
                } else {
                  response.selected = false;
                }
              });
              t += 1;
              w[
                question.contentful_id + subQuestion.response.contentful_id
              ] -= 1;
            });
          }
        } else {
          if (existsElementOnArray(question.contentful_id, responsesSection))
            previousResponse = responsesSection.filter(
              item => item.questionId == question.contentful_id
            );

          question.subQuestions.map(subQuestion => {
            if (
              existsElementOnArray(
                subQuestion.question.contentful_id,
                previousResponse[0].subQuestions
              )
            )
              previousResponse2 = previousResponse[0].subQuestions.filter(
                item => item.questionId == subQuestion.question.contentful_id
              );

            subQuestion.question.responseOptions.map(response => {
              w[question.contentful_id + response.contentful_id] = 1;
              response.selected =
                previousResponse2[0].responseId == response.contentful_id
                  ? true
                  : false;
            });
            t += 1;
          });
        }
      });

      setInitSection(true);
    } else {
      data.questions.map(question => {
        if (question.responseOptions) {
          question.responseOptions.map(response => {
            w[question.contentful_id + response.contentful_id] = 1;
          });
          t += 1;
          if (question.subQuestions) {
            question.responseOptions.map(response => {
              w[question.contentful_id + response.contentful_id] += 1;
            });
            question.subQuestions.map(subQuestion => {
              subQuestion.question.responseOptions.map(response => {
                w[
                  subQuestion.question.contentful_id + response.contentful_id
                ] = 1;
              });
              t += 1;
              w[
                question.contentful_id + subQuestion.response.contentful_id
              ] -= 1;
            });
          }
        } else {
          question.subQuestions.map(subQuestion => {
            subQuestion.question.responseOptions.map(response => {
              w[question.contentful_id + response.contentful_id] = 1;
            });
            t += 1;
          });
        }
      });
    }

    setTotalResponses(t);
    setWeightingResponses(w);
  }, [sectionNumber]);

  const moveDown = elementId => {
    setTimeout(function() {
      scrollTo('#' + elementId);
    }, 500);
  };

  const goNext = () => {
    if (next) {
      goToStep();
    } else {
      setShowModalNext(true);
    }
  };

  useEffect(() => {
    let count = 0;

    responsesSection.map(question => {
      if (question.subQuestions) {
        if (question.responseId) {
          count +=
            weightingResponses[question.questionId + question.responseId];
          question.subQuestions.map(subQuestion => {
            count +=
              weightingResponses[
                subQuestion.questionId + subQuestion.responseId
              ];
          });
        } else {
          question.subQuestions.map(subQuestion => {
            count +=
              weightingResponses[question.questionId + subQuestion.responseId];
          });
        }
      } else {
        count += weightingResponses[question.questionId + question.responseId];
      }
    });

    if (totalResponses - count > 0) setNext(false);
    else setNext(true);
  }, [responsesSection]);

  const back = () => {
    if (
      responsesSection.length > 0 ||
      (arreResponsesPreviousSection.length > 0 &&
        arreResponsesPreviousSection[sectionNumber])
    ) {
      if (
        JSON.stringify(arreResponsesPreviousSection[sectionNumber]) ===
        JSON.stringify(responsesSection)
      )
        goBack();
      else setShowModalBack(true);
    } else {
      goBack();
    }
  };

  /* Back button browser */
  window.history.pushState({ page: 1 }, '', '');
  window.onpopstate = event => event && back();

  return (
    <Fragment key={sectionNumber}>
      <StyledRectangle bg="white" py={5} my={5}>
        <Box px={5}>
          <CardSubtitle>{data.name}</CardSubtitle>
          <Line height="3px" color="red" my={1} />
        </Box>
        {data.questions.map((question, index) => (
          <Fragment key={question.contentful_id}>
            <Box
              color="white"
              mb={index > 0 ? '10' : '0'}
              id={'q' + (index + 1)}
            >
              quiz
            </Box>
            <Box px={5} mb={12}>
              <Question number={index + 1} question={question.questionText} />
              <ResponseOptions
                questionId={question.contentful_id}
                responseOptions={question.responseOptions}
                subQuestions={question.subQuestions}
                responsesSection={responsesSection}
                setResponsesSection={setResponsesSection}
                number={index + 1}
                moveDown={moveDown}
                initSection={initSection}
              />
            </Box>
            {index < data.questions.length - 1 && (
              <Line height="1px" color="#dbe2e7" />
            )}
          </Fragment>
        ))}
      </StyledRectangle>
      <Flex
        justifyContent="space-between"
        width={1}
        px={10}
        pt={5}
        sx={{
          paddingBottom: '100px'
        }}
      >
        <Button variant="bDarkRed" width="82px" onClick={() => back()}>
          Back
        </Button>
        <Button
          variant="bDarkRed"
          width={sectionNumber == Object.keys(steps).length ? '101px' : '82px'}
          onClick={() => goNext()}
        >
          {sectionNumber == Object.keys(steps).length ? 'Submit' : 'Next'}
        </Button>
      </Flex>
      <ModalConfirm
        description="Selected answers on this section will be lost."
        question="Do you still want to go back?"
        confirmMethod={goBack}
        showModal={showModalBack}
        setShowModal={setShowModalBack}
      />
      <Modal
        description="All questions are mandatory."
        textButton="Got it!"
        showModal={showModalNext}
        setShowModal={setShowModalNext}
      />
    </Fragment>
  );
};

export default QuizSection;
