import React from 'react';
import { Text } from 'rebass/styled-components';
import { Label, Radio } from '@rebass/forms';

const ResponseRadioButton = ({
  questionId,
  subQuestionId,
  responseId,
  responseText,
  handleClickResponseSubQuestions,
  lastSQ,
  checked
}) => {
  return (
    <Label width={1 / 3} alignItems="center" my={3}>
      <Radio
        mr={2}
        ml={2}
        color="#8b97a2"
        sx={{
          'input:checked ~ &': {
            color: '#d04c50'
          }
        }}
        id={subQuestionId + responseId}
        name={subQuestionId}
        defaultChecked={checked}
        onClick={() =>
          handleClickResponseSubQuestions(
            questionId,
            subQuestionId,
            responseId,
            lastSQ
          )
        }
      />
      <Text>{responseText}</Text>
    </Label>
  );
};

export default ResponseRadioButton;
