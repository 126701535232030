import React, { useState, useEffect } from 'react';
import { Flex } from 'rebass/styled-components';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import StepsHeader from '../components/StepsHeader';
import QuizSection from './QuizSection';
import { STORAGE_KEYS } from '../resources/constants';
import {
  startQuizService,
  storeSectionQuizService,
  completeQuizService
} from '../services/services';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import FadeLoader from 'react-spinners/FadeLoader';

const Quiz = props => {
  const [step, setStep] = useState(1);
  const [steps, setSteps] = useState([]);
  const [sectionsId, setSectionsId] = useState([]);
  const [dataSections, setDataSections] = useState(null);
  const [responsesSection, setResponsesSection] = useState([]);
  const [
    arreResponsesPreviousSection,
    setArreResponsesPreviousSection
  ] = useState([]);
  const [initSection, setInitSection] = useState(false);
  const [patientIdExists, setPatientIdExists] = useState(false);
  const [loading, setLoading] = useState(false);

  const data_content = useStaticQuery(
    graphql`
      query MyQuery {
        contentfulQuiz {
          contentful_id
          name
          sections {
            contentful_id
            name
            number
            questions {
              contentful_id
              questionText
              responseOptions {
                contentful_id
                responseText
                icon {
                  file {
                    url
                    fileName
                  }
                }
              }
              subQuestions {
                contentful_id
                question {
                  contentful_id
                  questionText
                  responseOptions {
                    contentful_id
                    responseText
                    icon {
                      file {
                        url
                        fileName
                      }
                    }
                  }
                }
                response {
                  contentful_id
                  responseText
                }
              }
            }
          }
        }
      }
    `
  );

  const startQuiz = () => {
    trackCustomEvent({
      category: 'quiz',
      action: 'start'
    });

    if (!localStorage.getItem(STORAGE_KEYS.QUIZ_ID)) {
      startQuizService()
        .then(response => {
          if (response.status == '200') {
            localStorage.setItem(STORAGE_KEYS.QUIZ_ID, response.data.id);
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  const storeSection = responsesSection => {
    setLoading(true);
    trackCustomEvent({
      category: 'quiz',
      action: `step - ${step + 1}`
    });

    storeSectionQuizService(sectionsId[step], responsesSection)
      .then(response => {
        const aux = [...arreResponsesPreviousSection];
        aux[step] = responsesSection;
        setArreResponsesPreviousSection(aux);
        localStorage.setItem(
          STORAGE_KEYS.QUIZ_RESPONSES_SECTION,
          JSON.stringify(aux)
        );

        const next = step + 1;

        if (arreResponsesPreviousSection[next])
          setResponsesSection(arreResponsesPreviousSection[next]);
        else setResponsesSection([]);

        navigate(`/quiz/${next}`);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const completeQuiz = responsesSection => {
    trackCustomEvent({
      category: 'quiz',
      action: `complete`
    });

    var data = {
      lastSectionId: sectionsId[step],
      sectionResponses: responsesSection
    };
    completeQuizService(data)
      .then(response => {
        if (response.status == '200') {
          localStorage.setItem(STORAGE_KEYS.QUIZ_COMPLETE, true);
          navigate('/analysis');
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const goToStep = () => {
    if (step + 1 <= Object.keys(steps).length) {
      setInitSection(true);
      storeSection(responsesSection);
    } else {
      completeQuiz(responsesSection);
    }
  };

  const goBack = () => {
    setLoading(true);
    setInitSection(false);
    var previous = step - 1;
    if (previous == 0) navigate('/');
    else {
      const aux = arreResponsesPreviousSection[previous];
      setResponsesSection(aux);

      navigate(`/quiz/${previous}`);
    }
  };

  useEffect(() => {
    setStep(parseInt(props.id));
    setLoading(false);
  }, [props.id]);

  useEffect(() => {
    if (!localStorage.getItem(STORAGE_KEYS.ID)) {
      navigate('/');
    } else {
      let aux = JSON.parse(
        localStorage.getItem(STORAGE_KEYS.QUIZ_RESPONSES_SECTION)
      );
      if (aux) {
        setArreResponsesPreviousSection(aux);

        if (aux[step]) setResponsesSection(aux[step]);
      }

      setPatientIdExists(true);
      var steps = [];
      var data = [];
      var sectionsId = [];

      data_content.contentfulQuiz.sections.map(section => {
        var id = section.contentful_id;
        var name = section.name;
        var number = section.number;
        steps[name] = number;
        data[number] = section;
        sectionsId[number] = id;
      });

      setSteps(steps);
      setDataSections(data);
      setSectionsId(sectionsId);
      startQuiz();
    }
  }, []);

  // unmount
  useEffect(() => () => {}, []);

  if (!dataSections || !patientIdExists) return null;
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      maxWidth="550px"
      width="100%"
      mb={5}
      mt={12}
    >
      <StepsHeader title="CSQ Quiz" currentStep={step} steps={steps} />
      {loading && (
        <Flex
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          my={5}
        >
          <FadeLoader
            height={15}
            width={5}
            radius={2}
            color={'#ff5e63'}
            loading="true"
          />
        </Flex>
      )}
      {!loading && (
        <QuizSection
          sectionNumber={step}
          data={dataSections[step]}
          goToStep={goToStep}
          steps={steps}
          goBack={goBack}
          initSection={initSection}
          setInitSection={setInitSection}
          responsesSection={responsesSection}
          setResponsesSection={setResponsesSection}
          arreResponsesPreviousSection={arreResponsesPreviousSection}
        />
      )}
    </Flex>
  );
};

export default Quiz;
