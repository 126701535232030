import React from 'react';
import Layout from '../components/Layout';
import Quiz from '../sections/Quiz';
import { Router } from '@reach/router';

const QuizPage = () => (
  <Layout logoHeader={false} mainHeader={false}>
    <Router>
      <Quiz path="/quiz/:id" />
    </Router>
  </Layout>
);

export default QuizPage;
