import React, { Fragment } from 'react';
import { Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import ResponseRadioButton from '../components/ResponseRadioButton';

const StyledTitle = styled(Text)`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.14;
  color: #161f26;
`;

const SubQuestions = ({
  questionId,
  subQuestions,
  handleClickResponseSubQuestions,
  lastSQ
}) => {
  return (
    <Fragment>
      {subQuestions.map((subQuestion, index) => (
        <Flex
          px={5}
          flexWrap="no-wrap"
          key={subQuestion.contentful_id}
          alignItems="center"
        >
          <StyledTitle width={2 / 3}>
            {subQuestion.question.questionText}
          </StyledTitle>
          {subQuestion.question.responseOptions &&
            subQuestion.question.responseOptions.map(option => (
              <ResponseRadioButton
                key={option.contentful_id}
                questionId={questionId}
                subQuestionId={subQuestion.question.contentful_id}
                responseId={option.contentful_id}
                responseText={option.responseText}
                handleClickResponseSubQuestions={
                  handleClickResponseSubQuestions
                }
                lastSQ={index == subQuestions.length - 1}
                checked={option.selected}
              />
            ))}
        </Flex>
      ))}
    </Fragment>
  );
};

export default SubQuestions;
