import React, { Fragment } from 'react';
import { Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import Question from '../components/Question';
import Response from '../components/Response';

const SubQuestion = ({
  questionId,
  subQuestions,
  number,
  handleClickResponse,
  activeQ,
  activeSQ
}) => {
  return (
    <Fragment>
      {subQuestions.map(subQuestion => (
        <Flex
          key={subQuestion.contentful_id}
          flexDirection="column"
          alignItems="center"
          style={{
            display:
              activeQ == subQuestion.response.contentful_id ? 'block' : 'none'
          }}
        >
          <Question
            number={number + 'a'}
            question={subQuestion.question.questionText}
          />
          {subQuestion.question.responseOptions &&
            subQuestion.question.responseOptions.map(option => (
              <Response
                key={option.contentful_id}
                questionId={questionId}
                subQuestionId={subQuestion.question.contentful_id}
                responseId={option.contentful_id}
                responseText={option.responseText}
                icon={option.icon}
                width={option.responseText.length > 50 ? '250px' : '200px'}
                handleClickResponse={handleClickResponse}
                isActive={activeSQ === option.contentful_id}
                hasSQ={false}
              />
            ))}
        </Flex>
      ))}
    </Fragment>
  );
};

export default SubQuestion;
